import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from "axios";

axios.defaults.baseURL = 'https://dsh.lazismukabupatenblitar.rsuaminahblitar.com/dsh-api' 
// axios.defaults.baseURL = 'http://192.168.100.112:8001'

import ContentHeader from "@/components/ContentHeader.vue";
import Content from "@/components/Content.vue";

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import "./assets/css/style.css";

const app = createApp(App)
app.use(store)
app.use(router)
app.component('content-header', ContentHeader)
app.component('content', Content)  
app.component('EasyDataTable', Vue3EasyDataTable);
app.mount('#app')
