<template>
  <content-header title="Donasi Cash"></content-header>
  <content>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h3>Donasi Cash Pending</h3>
          </div>
          <div class="card-body">
            <h6 class="mb-3">Menampilkan data donasi cash/uang yang masih belum di validasi oleh Admin</h6>
            <EasyDataTable :headers="donasi.pending.headers" :items="donasi.pending.items">
              <template #item-donatur="item">
                {{ item.nama }}
              </template>

              <template #item-nominal="item">
                {{ item.donasi }}
              </template>

              <template #item-dokumentasi="item">
                <span v-if="item.photo_file != null && item.photo_file != ''">
                  file: {{ item.photo_file }} <span class="badge bg-success pointer">open</span>
                </span>
              </template>

              <template #item-amil="item">
                {{ item.amil }}
              </template>

              <template #item-status="item">
                {{ item.statusDonasi }}
              </template>

              <template #item-option="item">
                <span v-if="item.statusDonasi == 'Pending'">
                  <button class="btn btn-xs bg-olive mr-1 mb-1" @click="confirmTerimaDonasi(item)">Terima</button>
                  <!-- <button class="btn btn-xs bg-orange mb-1" @click="confirmTolakDonasi(item)">Tolak</button> -->
                </span>
              </template>



            </EasyDataTable>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h3>Riwayat Donasi (Store procedure belum ada)</h3>
          </div>
          <div class="card-body">
            <h6 class="mb-3">Menampilkan data donasi yang sudah di validasi oleh admin</h6>
            <EasyDataTable :headers="donasi.donasi.headers" :items="donasi.donasi.items">
              <template #item-donatur="item">
                {{ item.nama }}
              </template>

              <template #item-nominal="item">
                {{ item.donasi }}
              </template>

              <template #item-dokumentasi="item">
                <span v-if="item.photo_file != null && item.photo_file != ''">
                  file: {{ item.photo_file }} <span class="badge bg-success pointer">open</span>
                </span>
              </template>

              <template #item-amil="item">
                {{ item.amil }}
              </template>

              <template #item-status="item">
                {{ item.statusDonasi }}
              </template>

              <template #item-option="item">
                <span v-if="item.statusDonasi == 'Pending'">
                  <button class="btn btn-xs bg-olive mr-1 mb-1" @click="confirmTerimaDonasi(item)">Terima</button>
                  <!-- <button class="btn btn-xs bg-orange mb-1" @click="confirmTolakDonasi(item)">Tolak</button> -->
                </span>
              </template>



            </EasyDataTable>
          </div>
        </div>
      </div>
    </div>
  </content>


</template>

<script setup>

import { onMounted, computed, ref, reactive } from 'vue';
import axios from "axios";
import { useStore } from "vuex";
import Modal from "@/components/Modal.vue";
import Swal from 'sweetalert2';

const store = useStore();

const user = computed(() => {
  return store.state.auth.user
})

const utils = reactive({
  url_donasi_cash_pending: "api/donasi",
  modal: {
    add_user: false
  }
})

const reward = ref("")

const donasi = reactive({
  pending: {
    headers: [
      { text: 'tanggal', value: 'tanggal' },
      { text: 'donatur', value: 'donatur' },
      { text: 'nominal', value: 'nominal' },
      { text: 'dokumentasi', value: 'dokumentasi' },
      { text: 'amil', value: 'amil' },
      { text: 'status', value: 'status' },
      { text: 'aksi', value: 'option' },
    ],
    items: [],
  },
  donasi: {
    headers: [
      { text: 'tanggal', value: 'tanggal' },
      { text: 'donatur', value: 'donatur' },
      { text: 'nominal', value: 'nominal' },
      { text: 'dokumentasi', value: 'dokumentasi' },
      { text: 'amil', value: 'amil' },
      { text: 'status', value: 'status' },
      // { text: 'aksi', value: 'option' },
    ],
    items: [],
  }
})

function init() {
  fetchDonasiCashPending()
  fetchDonasiCash()
}

const fetchDonasiCashPending = async () => {
  let params = {
    username: user.value.username
  }

  await axios.get(`${utils.url_donasi_cash_pending}/donasi-uang-pending`, { params })
    .then((a) => {
      
      if (a.data.code == 200) {
        donasi.pending.items = a.data.response
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {

    })
}

const fetchDonasiCash = async () => {
  // let params = {
  //   username: user.value.username
  // }
}

const confirmTerimaDonasi = async (item) => {
  reward.value = ""
  const { value: isSetReward } = await Swal.fire({
    title: `Apakah Anda yakin? ${reward.value}`,
    html: `
    <div>Aksi ini akan merubah status donasi pending menjadi diterima</div> 
    <div class="form-group">
      <label for="reward">Tambahkan Reward kepada ${item.amil}</label>
      <input type="number" id="input_reward" class="form-control" v-model="reward" placeholder="reward">  
    </div>
    ` ,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ya, Terima!',
    preConfirm: () => {
      const rewardinput = document.getElementById('input_reward').value
      if (!rewardinput) {
        Swal.showValidationMessage(
          'Anda harus memberikan nilai pada colom reward'
        )
      }

      return rewardinput
    }


  })
  if (isSetReward) {
    reward.value = isSetReward
    let params = {
      id: String(item.id),
      user: String(user.value.username),
      status: "1",
      reward: String(reward.value)

    }
    console.log(params);

    axios.post(`${utils.url_donasi_cash_pending}/approve-donasi-uang`, params)
      .then((a) => {
        console.log(a.data);
        if (a.data.code == 200) {
          Swal.fire('Terima Kasih', 'Donasi Berhasil', 'success')
          fetchDonasiCashPending()
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        
      })
    
  }

}

onMounted(() => {
  init()
})

</script>

<style></style>